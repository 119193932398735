import { useEffect, useState } from "react";
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import { lists_navigation, navigation } from "@/constants/navigation";
import logoHorizontal from "@/assets/images/logo_horizontal.svg";
import { AppsNavigator } from "../AppsNavigator";
import { LogoIcon } from "@/components/icons/logo";
import { ChevronLeftSmall } from "@/components/icons/chevron-left-small";
import { useSideMenuStore } from "@/store/sideMenuStore";
import { useGetFlipBooleanFlag } from "@/helpers/get-flipt-flag";

/*
 *
 * The site header and navbar
 *
 */
const Header = () => {
    const location = useLocation();
    const active = location?.pathname?.split("/").join(",/").split(",");
    const [isDrawerVisible, setDrawerVisible] = useState(false);
    const { isCollapsed, setIsCollapsed } = useSideMenuStore();
    const [navigationList, setNavigationList] = useState<any[]>(navigation);

    const { isEnabled: isListsEnabled, isLoading: isListsLoading } =
        useGetFlipBooleanFlag("allow-deny");

    useEffect(() => {
        if (isListsEnabled && !isListsLoading) {
            setNavigationList((prev) => [
                ...prev.slice(0, prev.length - 1),
                lists_navigation,
                prev[prev.length - 1],
            ]);
        }
    }, [isListsEnabled, isListsLoading]);

    return (
        <header
            className={`main-header ${
                isCollapsed ? "main-header--collapsed" : ""
            }`}
        >
            <span
                className="chevron-icon"
                onClick={() => setIsCollapsed(!isCollapsed)}
            >
                <ChevronLeftSmall
                    style={{ border: "1px solid #fff", borderRadius: "50%" }}
                />
            </span>

            {isDrawerVisible && (
                <div
                    className="overlay"
                    onClick={() => setDrawerVisible(false)}
                />
            )}
            <button
                className="nav__open-drawer-button"
                onClick={() => setDrawerVisible(true)}
            />
            <div className="logo-container">
                <span
                    className={`collapsed-logo ${
                        isCollapsed ? "collapsed-logo--show" : ""
                    }`}
                >
                    <img
                        src="/logo.jpg"
                        alt="Focal's logo"
                        style={{ width: "21", height: "24" }}
                    />
                    <span className="logo-text">Fraud</span>
                </span>
                <div
                    className={`expanded-logo ${
                        isCollapsed ? "expanded-logo--hide" : ""
                    }`}
                >
                    <AppsNavigator />

                    <NavLink to="/" className="logo" aria-label="Focal">
                        <img src={logoHorizontal} alt="Focal's logo" />
                        <span className="logo-text">Fraud</span>
                    </NavLink>
                </div>
            </div>

            <nav
                className={
                    "nav" + (isDrawerVisible ? " nav--drawer-visible" : "")
                }
            >
                <button
                    className="nav__close-drawer-button"
                    onClick={() => setDrawerVisible(false)}
                />
                <h3 className="nav__full-name">USER</h3>
                <ul className="nav__group dashboard_menu">
                    {navigationList.map((i: any, index) => (
                        <li
                            className={
                                "nav__item" +
                                (active?.indexOf(i.url) !== -1
                                    ? " nav__item--active"
                                    : "")
                            }
                            key={`nav__item-${index}`}
                        >
                            <NavLink
                                key={i.url}
                                onClick={() => setDrawerVisible(false)}
                                to={i.url}
                            >
                                {i.icon && (
                                    <i.icon
                                        className={`nav__item__icon${
                                            i.label === "Devices"
                                                ? "-stroke"
                                                : ""
                                        }`}
                                    />
                                )}
                                <span className="item-label">{i.label}</span>
                            </NavLink>
                            {i.subItems && (
                                <ul
                                    className={
                                        active?.indexOf(i.url) !== -1
                                            ? " nav--expanded"
                                            : ""
                                    }
                                >
                                    {i.subItems.map(
                                        (subItem: any, index: number) => {
                                            let match = false;
                                            subItem?.routes?.forEach(
                                                (routeURL: any) => {
                                                    const isMatch =
                                                        // eslint-disable-next-line
                                                        useRouteMatch({
                                                            path: routeURL,
                                                            exact: true,
                                                        });
                                                    if (isMatch) match = true;
                                                }
                                            );

                                            return (
                                                <li
                                                    key={index}
                                                    className={
                                                        "nav__sub_item" +
                                                        (match
                                                            ? " nav__sub_item--active"
                                                            : "")
                                                    }
                                                >
                                                    <NavLink
                                                        onClick={() =>
                                                            setDrawerVisible(
                                                                false
                                                            )
                                                        }
                                                        to={subItem.url}
                                                    >
                                                        <span className="item-label">
                                                            {subItem.label}
                                                        </span>
                                                    </NavLink>
                                                </li>
                                            );
                                        }
                                    )}
                                </ul>
                            )}
                        </li>
                    ))}
                </ul>
                <div
                    className={`nav__group user_menu ${
                        isCollapsed ? "user_menu--collapsed" : ""
                    }`}
                >
                    <span className="power-by"> Powered by</span>
                    <LogoIcon />
                </div>
            </nav>
        </header>
    );
};

export { Header };
